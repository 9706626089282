export default class Utils {
  static isType<T>(objectToBeValidated: any, validatingKey: string) {
    const isObject = typeof objectToBeValidated === "object";

    if (isObject && objectToBeValidated && objectToBeValidated[validatingKey]) {
      return objectToBeValidated as T;
    }

    return false;
  }
}
