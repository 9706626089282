const DEFAULTS = {
  TIMER: 200,
};

const _getDisabledReviews = (nodeList: NodeListOf<Element>) => {
  return [...nodeList].filter((reviewElement) => {
    return (
      !reviewElement.hasAttribute("active") &&
      !reviewElement.hasAttribute("displayed")
    );
  });
};

const _activate = (review: Element) => {
  if (review) {
    review.setAttribute("active", "");
    review.setAttribute("displayed", "");
  }
};

const _resetNodes = (nodeList: NodeListOf<Element>) => {
  nodeList.forEach((review) => {
    review.removeAttribute("active");
    review.removeAttribute("displayed");
  });

  _activate(nodeList[0]);
  
  return Number(nodeList[0].getAttribute("delay") ?? DEFAULTS.TIMER)
};

const _disableCurrent = (nodeList: NodeListOf<Element>) => {
  const reviews = [...nodeList];

  reviews.forEach((review) => {
    if (review && review.hasAttribute("active")) {
      review.removeAttribute("active");
    }
  });
};

const _activateNext = (nodeList: NodeListOf<Element>) => {
  const reviewsDisabled = _getDisabledReviews(nodeList);

  if (reviewsDisabled.length) {
    const reviewElement = [...reviewsDisabled][0];
    _disableCurrent(nodeList);

    reviewElement.setAttribute("active", "");
    reviewElement.setAttribute("displayed", "");

    return Number(reviewElement.getAttribute("delay") ?? DEFAULTS.TIMER)
  } else {
    return _resetNodes(nodeList);
  }
};

export const reviewerCarrousel = () => {
  const carrouselContainer = document.querySelector(".reviewSlideShow");

  if (carrouselContainer) {
    const carrouselReviews = carrouselContainer.querySelectorAll(".reviewItem");

    const timer = _activateNext(carrouselReviews);
    setTimeout(reviewerCarrousel, timer);
  }
};
