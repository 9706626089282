const _imagesReady = async () => {
  const images = document.querySelectorAll("img")
  const totalImages = images.length;
  let imageCompleteTracker = 0

  const _completeImage = () => {
    imageCompleteTracker++

    if (imageCompleteTracker === totalImages) {
      Promise.resolve()
    }
  }

  images.forEach(img => {
    img.onload = _completeImage

    if (img.complete) {
      _completeImage()
    }
  })
};

const _releaseLazyLoading = () => {
  const lazyLoading = document.querySelectorAll(".lazyLoad");

  lazyLoading.forEach((img) => {
    if (img) {
      img.classList.add("active");
    }
  });
};

const _releaseScroll = () => {
  const htmlContainer = document.querySelector("html");
  const body = document.querySelector("body")

  if (htmlContainer && body) {
    htmlContainer.removeAttribute("loading");
    body.style.overflowY = "auto";
  }
};

export const releaseLoaderWhenReady = async () => {
  const images = document.querySelectorAll("img");

  if (images.length) {
    await _imagesReady();

    _releaseScroll();
    _releaseLazyLoading()
  }
};
