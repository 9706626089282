import { runSliderPlugin } from "./before-after-slider";
import { reviewerCarrousel } from "./reviewers";
import { releaseLoaderWhenReady } from "./load-after-images";
import { fadeCaseImages } from "./fade-cases";
import { applyAnchors } from "./anchors";
import { appCreator } from "./vue-app";
import { redirectAfterGoogleConversion } from "./google-ad-conversion";

document.addEventListener("DOMContentLoaded", () => {
  runSliderPlugin();
  reviewerCarrousel();
  releaseLoaderWhenReady();
  fadeCaseImages();
  applyAnchors();
  redirectAfterGoogleConversion();
});
