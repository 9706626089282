const DEFAULTS = {
  timer: 3000,
};

const _getImages = () => {
  const cases = document.querySelector("#successCases");

  if (cases) {
    return [...cases.querySelectorAll("li img[fader]")] as HTMLImageElement[];
  }

  return [];
};

const _toggleFader = (nodes: HTMLImageElement[]) => {
  nodes.forEach((img) => {
    if (img.hasAttribute("active")) {
      img.removeAttribute("active");
    } else {
      img.setAttribute("active", "");
    }
  });
};

export const fadeCaseImages = () => {
  const images = _getImages();

  setTimeout(() => {
    _toggleFader(images)
    fadeCaseImages()
  }, DEFAULTS.timer);
};
