import Utils from "./utils";

export const runSliderPlugin = () => {
  const container = Utils.isType<HTMLElement>(
    document.querySelector(".container"),
    "style"
  );

  const slider = document.querySelector(".slider");

  if (slider && container) {
    slider.addEventListener("input", (event) => {
      if (event) {
        const input = event.target as HTMLInputElement;
        container.style.setProperty("--position", `${input.value}%`);
      }
    });
  }
};
