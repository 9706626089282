const _findAnchorTriggers = () => {
  return [...document.querySelectorAll("[slide-to]")];
};

const _slideToAnchor = (anchorName: string) => {
  const elementInDom = document.querySelector(`[anchor="${anchorName}"]`);
  const body = document.querySelector("body") as Element

  if (elementInDom) {
    const nudgeOffset = Number(elementInDom.getAttribute("nudge") ?? 0)
    const offsetY = elementInDom.getBoundingClientRect().y - nudgeOffset;

    body.scroll({
        top: offsetY,
        behavior: "smooth"
    });
  }
};

export const applyAnchors = () => {
  const triggers = _findAnchorTriggers();

  triggers.forEach((triggerPoint) => {
    triggerPoint.addEventListener("click", (event) => {
      const theElement = event.target as Element;

      if (theElement) {
        _slideToAnchor(theElement.getAttribute("slide-to") ?? "");
      }
    });
  });
};
